@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face{
        font-family:"averta";
        src:url("./fonts/averta/5b6b0808-79db-4639-ab19-365f715f83b9.woff2") format("woff2"),url("./fonts/averta/0d9ceccf-0cd7-44dc-8c2a-dbc0ed90f149.woff") format("woff");
        font-weight: 700;
        font-style: normal;
    }
    @font-face{
        font-family:"averta";
        src:url("./fonts/averta/a865642b-c657-4a8c-b31c-3387daa11432.woff2") format("woff2"),url("./fonts/averta/2d64d960-d035-4629-99e9-5571504beb94.woff") format("woff");
        font-weight: 700;
        font-style: italic;
    }
    @font-face{
        font-family:"averta";
        src:url("./fonts/averta/a5a6b226-c201-4c7e-aa75-6d01874f1a83.woff2") format("woff2"),url("./fonts/averta/09560f34-5e51-44e7-962d-dbb751f7dee4.woff") format("woff");
        font-weight: 400;
        font-style: italic;
    }
    @font-face{
        font-family:"averta";
        src:url("./fonts/averta/166ce875-a649-4826-a5a0-a24f2dd89cd5.woff2") format("woff2"),url("./fonts/averta/fc417db9-6d91-4c85-81b1-2ff3d583d149.woff") format("woff");
        font-weight: 400;
        font-style: normal;
    }
    @font-face{
        font-family:"averta";
        src:url("./fonts/averta/b7fbd7d4-32a7-453d-84d3-1345247e48bb.woff2") format("woff2"),url("./fonts/averta/934be726-c31d-448f-9d56-76f74f872f91.woff") format("woff");
        font-weight: 900;
        font-style: italic;
    }
    @font-face{
        font-family:"averta";
        src:url("./fonts/averta/91271893-3829-4dc9-a79b-a47b7146022c.woff2") format("woff2"),url("./fonts/averta/17dd7da6-7e09-45f6-bae4-9a944baed5a6.woff") format("woff");
        font-weight: 900;
        font-style: normal;
    }
}